import React from 'react'
import { ThemeContext } from 'styled-components'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import { client } from 'services/contentful'
import { Link, Stack, Button, Body } from 'components'
//TODO: what to do about needing textfield directly
import TextField from 'components/Form/TextField'
import { useNotificationStore } from 'stores'

type Props = {
  price: number | string
  setActiveCoupon: (_: string) => void
  setPrice: (_: number) => void
}
const roundToTwoDecimalPoints = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100

export const CouponInput = ({ price, setActiveCoupon, setPrice }: Props) => {
  const [isInputVisible, setIsInputVisible] = React.useState(false)
  const [isCouponSet, setIsCouponSet] = React.useState(false)
  const theme = React.useContext(ThemeContext)

  const ValidationSchema = yup.object().shape({
    code: yup.string().required('This field is required')
  })

  if (!isInputVisible && !isCouponSet) {
    return (
      <Link
        as="p"
        textAlign="right"
        color={theme.colors.primaryRed}
        onClick={() => setIsInputVisible(true)}
      >
        Have a coupon? Click here!
      </Link>
    )
  }

  if (isInputVisible && !isCouponSet) {
    return (
      <Formik
        initialValues={{ code: '' }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          client
            .getEntries({
              content_type: 'couponCode',
              'fields.code': values.code
            })
            .then((res: any) => {
              if (res.items.length === 0) {
                actions.setFieldError(
                  'code',
                  'Hmmm...this looks like an invalid coupon code'
                )
              } else if (
                res.items.length > 0 &&
                moment().isAfter(moment(res.items[0].fields.expiry))
              ) {
                actions.setFieldError('code', 'Sorry, this code has expired!')
              } else {
                setIsInputVisible(false)
                setIsCouponSet(true)
                setPrice(
                  roundToTwoDecimalPoints(
                    Number(price) *
                      ((100 - res.items[0].fields.discountAmount) / 100)
                  )
                )
                setActiveCoupon(res.items[0].sys.id)
                useNotificationStore.getState().showNotification({
                  message: '😍 Coupon successfully added!'
                })
                '😍 Coupon successfully added!'
              }
            })
            .catch((error: any) => {
              actions.setFieldError(
                'code',
                'Hmm something went wrong. Try again later!'
              )
            })
        }}
      >
        {props => (
          <Form data-testid="form">
            <Stack justifyContent="flex-end">
              <TextField
                label="Enter Coupon Code"
                error={!!props.errors.code}
                id="code"
                name="code"
                value={props.values.code}
                InputProps={{
                  htmlFor: 'code'
                }}
                helperText={props.errors.code}
                onChange={props.handleChange}
              />

              <Button variant="text" type="submit" ml="auto">
                Apply Coupon
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    )
  }

  if (isCouponSet && !isInputVisible) {
    return (
      <Body variant="sm" color="greens.green40" textAlign="right">
        Coupon Applied!
      </Body>
    )
  }
}

export default CouponInput
